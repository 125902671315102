<template>
	<input
		ref="autocomplete"
		:aria-label="placeholder"
		type="text"
		:class="[
			autocompleteText ? 'defaultInputBorder' : 'requiredInputBorder',
			classname,
		]"
		:id="id"
		:placeholder="placeholder"
		v-model="autocompleteText"
		@keyup.enter="updateOnEnter"
	/>
</template>

<script>
import * as Sentry from "@sentry/vue";
const debounce = require("lodash/debounce");
const ADDRESS_COMPONENTS = {
	subpremise: "short_name",
	street_number: "short_name",
	route: "long_name",
	locality: "long_name",
	administrative_area_level_1: "short_name",
	administrative_area_level_2: "long_name",
	country: "long_name",
	postal_code: "short_name"
};
export default {
	name: "VueGoogleAutocomplete",
	props: {
		id: {
			type: String,
			required: true
		},
		classname: String,
		placeholder: {
			type: String,
			default: "Start typing"
		},
		types: {
			type: String,
			default: "address"
		},
		country: {
			type: [String, Array],
			default: null
		},
		defaultAddress: {
			type: String
		}
	},
	data() {
		return {
			/**
			 * The Autocomplete object.
			 *
			 * @type {Autocomplete}
			 * @link https://developers.google.com/maps/documentation/javascript/reference#Autocomplete
			 */
			autocomplete: null,
			/**
			 * Autocomplete input text
			 * @type {String}
			 */
			autocompleteText: this.defaultAddress
		};
	},
	watch: {
		country: function(newVal, oldVal) {
			this.autocomplete.setComponentRestrictions({
				country: this.country === null ? [] : this.country
			});
		}
	},
	mounted: function() {
		this.resetAutocomplete();
	},
	methods: {
		/**
		 * When a place changed
		 */
		onPlaceChanged: debounce(
			function() {
				this.$emit("showLoading");
				let place = this.autocomplete.getPlace();
				var self = this;
				if (!place || !place.geometry) {
					// User entered the name of a Place that was not suggested and
					// pressed the Enter key, or the Place Details request failed.
					var geocoder = new google.maps.Geocoder();
					geocoder.geocode(
						{
							address: self.autocompleteText
						},
						function(results, status) {
							if (
								status === google.maps.GeocoderStatus.OK &&
								(results[0].geometry.location_type ===
									"ROOFTOP" ||
									results[0].geometry.location_type ===
										"RANGE_INTERPOLATED" ||
									results[0].geometry.location_type ===
										"GEOMETRIC_CENTER")
							) {
								self.$emit(
									"placechanged",
									self.formatResult(results[0])
								);
								self.resetAutocomplete();
							} else {
								self.$emit("no-results-found", place, self.id);
								self.resetAutocomplete();
								Sentry.captureException(
									new Error(
										"Geocoder failed due to: " + status
									)
								);
							}
						}
					);
					return;
				}
				if (place.address_components !== undefined) {
					// return returnData object and PlaceResult object
					this.$emit(
						"placechanged",
						this.formatResult(place),
						place,
						this.id
					);
					// update autocompleteText
					this.autocompleteText = document.getElementById(
						this.id
					).value;
					this.resetAutocomplete();
				}
			},
			2000,
			{ leading: true, trailing: false }
		),
		resetAutocomplete() {
			const options = {};
			if (this.types) {
				options.types = [this.types];
			}
			if (this.country) {
				options.componentRestrictions = {
					country: this.country
				};
			}
			this.autocomplete = new google.maps.places.Autocomplete(
				document.getElementById(this.id),
				options
			);
			this.autocomplete.addListener("place_changed", this.onPlaceChanged);
			this.autocomplete.setFields(["address_components", "geometry"]);
		},
		/**
		 * Update the value of the input and call onPlaceChanged
		 * @param  {String} value
		 */
		update(value) {
			this.autocompleteText = value;
			this.onPlaceChanged();
		},
		updateOnEnter() {
			this.autocompleteText = this.$refs.autocomplete.value;
			this.onPlaceChanged();
		},
		/**
		 * Format result from Geo google APIs
		 * @param place
		 * @returns {{formatted output}}
		 */
		formatResult(place) {
			let returnData = {};
			for (let i = 0; i < place.address_components.length; i++) {
				let addressType = place.address_components[i].types[0];
				if (ADDRESS_COMPONENTS[addressType]) {
					let val =
						place.address_components[i][
							ADDRESS_COMPONENTS[addressType]
						];
					returnData[addressType] = val;
				}
			}
			returnData["latitude"] = place.geometry.location.lat();
			returnData["longitude"] = place.geometry.location.lng();
			return returnData;
		}
	}
};
</script>
<style lang="scss" scoped>
.requiredInputBorder {
	border: 4px solid $tomato;
}
.defaultInputBorder {
	border: 4px solid #fff;
}
</style>