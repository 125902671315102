<template>
	<section class="section section--orange">
		<div class="drives">
			<div class="drives__side drives__side--image">
				<img
					class="drives__image"
					:src="deliveryHeroImageURL"
					:alt="deliveryHeroImageAlt"
				/>
			</div>
			<div class="drives__side drives__side--text">
				<div class="drives__content">
					<h2 class="section__title section__title--white">
						{{ deliveryHeroTitle }}
					</h2>
					<p class="drives__copy">
						{{ deliveryHeroCopy }}
					</p>
					<a class="button button--blue" :href="deliveryHeroLink">{{
						deliveryHeroBtnText
					}}</a>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		deliveryHeroTitle: String,
		deliveryHeroCopy: String,
		deliveryHeroBtnText: String,
		deliveryHeroLink: String,
		deliveryHeroImageURL: String,
		deliveryHeroImageAlt: String
	}
};
</script>