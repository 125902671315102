import Vue from 'vue'
import HomePage from './components/home-page-components/HomePage.vue';
import HomePageHeaderLogo from './components/page-components/HomePageHeaderLogo.vue';
import store from './homePageStore/homePageIndex';
// import * as Sentry from "@sentry/vue";
// import { Integrations as TracingIntegrations } from "@sentry/tracing";

Vue.config.productionTip = false

new Vue({
  render: h => h(HomePageHeaderLogo),
  store,
}).$mount('#headerLogo')

new Vue({
  render: h => h(HomePage),
  store,
}).$mount('#home-page')


// Sentry.init({
//   Vue: Vue,
//   dsn: "https://546797d7309548318f8fedd3582943e9@o211199.ingest.sentry.io/1336256",
//   integrations: [new TracingIntegrations.BrowserTracing()],
//   tracingOptions: {
//     trackComponents: true,
//   },
//   tracesSampleRate: .1,
//   logErrors: true
// });