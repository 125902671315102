<template>
  <div class="cities">
    <div class="cities__content">
      <!--TODO: TOC Expansion: NC hard coded -->
      <div class="cities__title">We serve these NC cities:</div>
      <ul class="cities__list">
        <li v-for="(market, i) in markets" :key="i" class="cities__list-item">
          <a class="cities__link" :href="`/${market.URLCity}/restaurants/delivery`">{{ market.defaultCity }}</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    markets: {
      type: Array,
      required: true,
    },
  },
}
</script>
