import Vue from "vue";
import Vuex from "vuex";
import homePage from "./homePageModules/home-page-store";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

Vue.prototype.$CDN = CDN;

export default new Vuex.Store({
  modules: {
    homePage,
    strict: debug
  }
});