<template>
	<section class="section section--padding">
		<div class="container">
			<h2 class="sr-only">Good food fast. How&rsquo;s that work?</h2>
			<div class="row">
				<div
					v-for="(entry, i) in featureSection"
					:key="i"
					class="col-sm-4"
				>
					<article class="info info--mobile-spacing">
						<img
							:class="[
								'info__image',
								entry.wideImage
									? 'info__image--nc'
									: 'info__image--grid',
							]"
							:src="entry.imageURL"
							:alt="entry.imageAlt"
						/>
						<h3 class="info__title">
							{{ entry.titleFirstLine }} <br />
							{{ entry.titleSecondLine }}
						</h3>
						<p class="info__copy">
							{{ entry.copy }}
						</p>
					</article>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
export default {
	props: {
		featureSection: Array
	}
};
</script>