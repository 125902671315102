<template>
	<div>
		<div class="row">
			<div class="col-md-7">
				<div
					class="mapCanvasRestList"
					id="map_canvas"
					tabindex="0"
				></div>
			</div>

			<div class="col-md-5">
				<div class="row">
					<div class="col-md-12">
						<div id="notfound" style="display: block">
							<h1 class="mapModalHeader">
								<span class="orangeTitle">Oops!</span> Google
								couldn’t find the address you entered.
							</h1>
							<p>
								Never fear! Drag the map pin to your approximate
								delivery location and click here
								<button
									class="mapModalSubmit"
									type="button"
									@click="addressSelected()"
								>
									Submit
								</button>
							</p>
						</div>
					</div>
				</div>
				<div v-if="streetAddress" class="row">
					<div class="col-md-12">
						<h2>Street Address</h2>
					</div>
				</div>
				<div v-if="streetAddress" class="row">
					<div class="col-md-12">
						<p>{{ streetAddress }}</p>
					</div>
				</div>
				<div v-if="error" class="row">
					<div class="col-md-12">
						<p class="noAddress">{{ error }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import * as Sentry from "@sentry/vue";
export default {
	mounted() {
		var lat, lng, myLatlng, zoneMap, addressMarker, markerNewLatLng;
		var self = this;
		myLatlng = new google.maps.LatLng(this.mapCenterLat, this.mapCenterLng);
		var mapDefaultZoom = this.mapDefaultZoom;
		if (typeof mapDefaultZoom !== "number") {
			mapDefaultZoom = parseInt(mapDefaultZoom);
		}
		var mapOptions = {
			zoom: mapDefaultZoom,
			center: myLatlng,
			panControl: false,
			zoomControl: true,
			mapTypeControl: false,
			scaleControl: true,
			streetViewControl: false,
			overviewMapControl: false,
			fullscreenControlOptions: {
				position: google.maps.ControlPosition.TOP_LEFT
			},
			mapTypeId: google.maps.MapTypeId.ROADMAP
		};
		zoneMap = new google.maps.Map(
			document.getElementById("map_canvas"),
			mapOptions
		);
		var geocoder = new google.maps.Geocoder();
		addressMarker = new google.maps.Marker({
			position: zoneMap.center,
			map: zoneMap,
			clickable: true,
			draggable: true,
			flat: true
		});
		var infoWindow = new google.maps.InfoWindow({
			content: ""
		});

		google.maps.event.addListener(addressMarker, "dragend", function(
			event
		) {
			self.error = "";
			markerNewLatLng = event.latLng;
			geocoder.geocode({ latLng: markerNewLatLng }, function(
				results,
				status
			) {
				if (status == google.maps.GeocoderStatus.OK) {
					if (results[0]) {
						self.streetAddress = results[0].formatted_address;
						infoWindow.setContent(results[0].formatted_address);
						infoWindow.open(zoneMap, addressMarker);
					} else {
						self.error = "No address found";
						Sentry.captureMessage(
							new Error(this.$root.$el.id + ": No address found")
						);
					}
				} else {
					self.error = "No results found";
					Sentry.captureException(
						new Error("Geocoder failed due to: " + status)
					);
				}
			});
		});
		document.getElementById("map_canvas").focus();
	},
	props: {
		mapDefaultZoom: {
			type: String
		},
		mapCenterLat: {
			type: String
		},
		mapCenterLng: {
			type: String
		}
	},
	data: function() {
		return {
			streetAddress: "",
			lat: "",
			lng: "",
			error: ""
		};
	},
	methods: {
		addressSelected() {
			if (!this.streetAddress) {
				this.error =
					"Please drag the map pin to your approximate delivery location.";
				return false;
			}
			this.$emit("addressSelected", this.streetAddress);
		}
	}
};
</script>
<style scoped>
.mapCanvasRestList {
	width: 100%;
	height: 400px;
}
@media only screen and (max-width: 767px) {
	.mapCanvasRestList {
		height: 350px;
	}
}
.orangeTitle {
	color: #f1560e;
}
.mapModalHeader {
	font-size: 25px;
}
.mapModalSubmit {
	background-color: #1783c4;
	color: #fff;
	padding: 4px;
}
.noAddress {
	color: #f1560f;
}
</style>