import Vue from "vue";
var axios = require("axios");
var Sentry = require("@sentry/vue");

const state = {
  homePageData: {}
};

export const actions = {
  setInitHomePageState({ commit }) {
		if (window.homePageData) {
      var homePageData = window.homePageData;
      commit("setHomePageData", homePageData);
		} else {
      Sentry.captureException(
				new Error("Home Page: Initial home page state failed to load.")
      );
			return;
		}
  },
  goToRestList({ commit, state }, payload) {
    var params = new URLSearchParams();
    params.append("mode", "whichzone");
    params.append('lat', payload.lat);
    params.append('lng', payload.lng);
    
    return axios
      .post("/selectZone.pl?", params)
      .then(response => {
        if (response.data.errors) {
          // OutOfArea should be the error returned
          alert(payload.address + " appears to be outside of our delivery area. \nHowever, we may still be able to take your order. \nPlease call the office at " + state.homePageData.locationInfo.businessPhone + " and we will \ntry our best to accomodate you.");
          return false;
        } else if (response.data.success) {
          if (response.data.success.blocked) {
            alert('Sorry! ' + response.data.success.blocked + '\n' + response.data.success.reason);
            return false;
          }
          window.location.href = '/'+response.data.success.URLCity+'/restaurants/delivery';
          return true;
        }
      })
      .catch(function (error) {
        Sentry.captureException(new Error('Home Page Error: request whichzone failed'));
        return false;
      })
  },
};

export const mutations = {
  setHomePageData(state, homePageData) {
    Vue.set(state, "homePageData", homePageData);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
}