<template>
	<div class="home-hero__side home-hero__side--content">
		<div class="home-hero__content">
			<h1 class="section__title">
				{{ titleFirstLine }}<br />
				{{ titleSecondLine }}
			</h1>
			<div class="map__title">{{ addressInputTitle }}</div>
			<input-address
				ref="address"
				:savedAddresses="savedAddresses"
				:isLoggedIn="locationInfo.isLoggedIn"
				:defaultAddress="defaultAddress"
				@address-set="getNewRestList"
				@noResults="showGoogleMap"
			/>
			<modal v-if="showMapModal" @hideModal="hideMapModal()">
				<google-map
					@addressSelected="handleMapAddressSelected"
					:mapDefaultZoom="locationInfo.mapDefaultZoom"
					:mapCenterLat="locationInfo.mapCenterLat"
					:mapCenterLng="locationInfo.mapCenterLng"
				></google-map>
			</modal>
		</div>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import InputAddress from "../address-components/InputAddress.vue";
import Modal from "../address-components/Modal.vue";
import GoogleMap from "../address-components/GoogleMap.vue";

const debounce = require("lodash/debounce");

export default {
	components: {
		InputAddress,
		Modal,
		GoogleMap
	},
	data: function() {
		return {
			showMapModal: false
		};
	},
	props: {
		locationInfo: {
			type: Object
		},
		titleFirstLine: String,
		titleSecondLine: String,
		addressInputTitle: String
	},
	computed: {
		savedAddresses() {
			var addresses = [];
			if (this.locationInfo.savedAddresses) {
				this.locationInfo.savedAddresses.forEach(address => {
					var profile = {};
					profile.id = address.id;
					var unitNum = "";
					if (address.unitNo) {
						unitNum = "Unit " + address.unitNo + ", ";
					}
					profile.address =
						address.street + ", " + unitNum + address.zip;
					profile.addressSend =
						address.street +
						" " +
						address.city +
						", " +
						address.state +
						" " +
						address.zip;
					profile.label = address.nickname;
					addresses.push(profile);
				});
			}

			return addresses;
		},
		defaultAddress() {
			let defaultAddress = "";
			if (
				this.locationInfo.deliveryProfileId &&
				this.locationInfo.savedAddresses
			) {
				const deliveryProfile = this.locationInfo.savedAddresses.find(
					profile =>
						profile.id === this.locationInfo.deliveryProfileId
				);
				defaultAddress =
					deliveryProfile.nickname +
					" - " +
					deliveryProfile.street +
					", " +
					deliveryProfile.zip;
			} else if (
				this.locationInfo.defaultAddress &&
				this.locationInfo.defaultCity &&
				this.locationInfo.defaultState &&
				this.locationInfo.defaultZip
			) {
				defaultAddress =
					this.locationInfo.defaultAddress +
					" " +
					this.locationInfo.defaultCity +
					", " +
					this.locationInfo.defaultState +
					" " +
					this.locationInfo.defaultZip;
			}
			return defaultAddress;
		}
	},
	methods: {
		...mapActions({
			goToRestList: "homePage/goToRestList"
		}),
		setCookie(c_name, value, exdays) {
			var exdate = new Date();
			exdate.setDate(exdate.getDate() + exdays);
			var c_value =
				escape(value) +
				(exdays === null ? "" : "; expires=" + exdate.toUTCString()) +
				"; path=/" +
				";SameSite=Lax";
			document.cookie = c_name + "=" + c_value;
		},
		getNewRestList: debounce(
			async function(addressData) {
				const {
					latitude,
					longitude,
					address,
					streetAddress,
					city,
					state,
					zip,
					deliveryProfileId
				} = addressData;
				var result = await this.goToRestList({
					lat: latitude,
					lng: longitude,
					address: address
				});
				if (!result) {
					this.$refs.address.error = "";
					this.$refs.address.showLoading = false;
				} else {
					this.setCookie("address", streetAddress, 1800);
					this.setCookie("city", city, 1800);
					this.setCookie("state", state, 1800);
					this.setCookie("zip", zip, 1800);
					if (deliveryProfileId) {
						this.setCookie(
							"deliveryProfile",
							deliveryProfileId,
							1800
						);
					} else {
						this.setCookie("deliveryProfile", "", 1800);
					}
				}
			},
			2000,
			{ leading: true, trailing: false }
		),
		showGoogleMap() {
			document.body.classList.add("not-scrollable");
			this.showMapModal = true;
		},
		hideMapModal() {
			document.body.classList.remove("not-scrollable");
			this.showMapModal = false;
		},
		handleMapAddressSelected(address) {
			this.hideMapModal();
			this.$refs.address.handleAddressSelect(address);
		}
	}
};
</script>