<template>
	<div class="logo_container">
		<a class="desktop_logo" :href="businessInfo.homePageURL"
			><img
				width="400"
				height="85.99"
				class="img-responsive menu_logo"
				:src="businessInfo.headerLogoURL"
				:alt="businessInfo.logoAltText"
				:title="businessInfo.logoTitle"
		/></a>
		<a class="mobile_logo" :href="businessInfo.homePageURL"
			><img
				width="200"
				height="40.61"
				class="img-responsive menu_logo"
				:src="businessInfo.headerLogoURL"
				:alt="businessInfo.logoAltText"
				:title="businessInfo.logoTitle"
		/></a>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
	created() {
		this.setInitHomePageState();
	},
	computed: {
		...mapState({
			businessInfo: (state) => state.homePage.homePageData.businessInfo,
		}),
	},
	methods: {
		...mapActions({
			setInitHomePageState: "homePage/setInitHomePageState",
		}),
	},
};
</script>
