<template>
  <div>
    <div v-if="error">
      {{ error }}
    </div>
    <main v-if="homePageCopy">
      <div class="homepage_header front_page">
        <div class="home-hero" id="hero-section">
          <home-hero-left
            :locationInfo="locationInfo"
            :titleFirstLine="homePageCopy.titleFirstLine"
            :titleSecondLine="homePageCopy.titleSecondLine"
            :addressInputTitle="homePageCopy.addressInputTitle"
          />
          <home-hero-right :market="market" :rightHeroImages="marketHeroImages" />
        </div>
      </div>
      <market-links :markets="markets" />
      <app-section
        :homePageLinks="homePageLinks"
        :appSectionTitle="homePageCopy.appSectionTitle"
        :appSectionCopy="homePageCopy.appSectionCopy"
        :appImageURL="homePageCopy.appImageURL"
      />
      <feature-section :featureSection="featureSection" />
      <!-- <section class='buy-lunch section section--blue section--padding'>
					<div class='container'>
						<h2 class='buy-lunch__title'>Keep your employees close and their lunch closer.</h2>
						<p class='buy-lunch__copy'>We make it easy to feed your office with fast and convenient online ordering for all employees, with just one invoice.</p>
						<a class='button' href='/corporate'>Get Started</a>
					</div>
				</section> 
			-->
      <owner-section :restOwners="restOwners" />
      <delivery-hero-section
        :deliveryHeroTitle="homePageCopy.deliveryHeroTitle"
        :deliveryHeroCopy="homePageCopy.deliveryHeroCopy"
        :deliveryHeroLink="homePageCopy.deliveryHeroLink"
        :deliveryHeroImageURL="homePageCopy.deliveryHeroImageURL"
        :deliveryHeroImageAlt="homePageCopy.deliveryHeroImageAlt"
        :deliveryHeroBtnText="homePageCopy.deliveryHeroBtnText"
      />
    </main>
    <out-foot :homePageLinks="homePageLinks" :businessSocial="businessSocial" :businessInfo="businessInfo" />
  </div>
</template>
<script>
import OutFoot from '../page-components/OutFoot.vue'
import DeliveryHeroSection from './DeliveryHeroSection.vue'
import OwnerSection from './OwnerSection.vue'
import FeatureSection from './FeatureSection.vue'
import AppSection from './AppSection.vue'
import MarketLinks from './MarketLinks.vue'
import HomeHeroLeft from './HomeHeroLeft.vue'
import HomeHeroRight from './HomeHeroRight.vue'
import { mapState } from 'vuex'

import axios from 'axios'

export default {
  components: {
    OutFoot,
    DeliveryHeroSection,
    OwnerSection,
    FeatureSection,
    AppSection,
    MarketLinks,
    HomeHeroLeft,
    HomeHeroRight,
  },
  async mounted() {
    var position = await this.getPosition()
    var params = new URLSearchParams()
    params.append('mode', 'whichzone')
    params.append('lat', position.lat)
    params.append('lng', position.lng)

    var selectZoneResponse = await axios
      .post('/selectZone.pl?', params)
      .then((response) => {
        if (response.data.errors) {
          return 0
        } else if (response.data.success) {
          if (response.data.success.blocked) {
            return 0
          }
          return response.data.success
        }
      })
      .catch(function (error) {
        return 0
      })

    this.zoneId = selectZoneResponse.zoneID
    this.market = selectZoneResponse.market
  },
  data: function () {
    return {
      zoneId: 0,
      market: 'general',
      error: null,
    }
  },
  computed: {
    ...mapState({
      locationInfo: (state) => state.homePage.homePageData.locationInfo,
      homePageLinks: (state) => state.homePage.homePageData.homePageLinks,
      businessSocial: (state) => state.homePage.homePageData.businessSocial,
      businessInfo: (state) => state.homePage.homePageData.businessInfo,
      rightHeroImages: (state) => state.homePage.homePageData.rightHeroImages,
      restOwners: (state) => state.homePage.homePageData.restOwners,
      featureSection: (state) => state.homePage.homePageData.featureSection,
      homePageCopy: (state) => state.homePage.homePageData.homePageCopy,
      markets: (state) => state.homePage.homePageData.markets,
    }),
    marketHeroImages: function () {
      var marketHeroImagesReturn = this.rightHeroImages.filter((image) => image.tag == this.market)
      if (marketHeroImagesReturn.length > 0) {
        return marketHeroImagesReturn
      } else if (this.businessInfo.subdomain.includes('mission')) {
        return this.rightHeroImages.filter((image) => image.tag == 'mission')
      } else if (this.businessInfo.subdomain.includes('unchealth')) {
        return this.rightHeroImages.filter((image) => image.tag == 'unchealth')
      }
      return this.rightHeroImages.filter((image) => image.tag == 'general')
    },
  },
  methods: {
    getPosition() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
          },
          (error) => {
            console.log(error.message)
          }
        )
      })
    },
  },
}
</script>
<style>
#home-page {
  color: #333;
}
#modal-background {
  background-color: #000;
  opacity: 0.5;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
}
.map__title {
  color: white;
}
.not-scrollable {
  overflow: hidden;
}
.modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal-content {
  height: 100%;
}
.modal-loading {
  display: block;
  text-align: right;
  margin-top: 5px;
  margin-right: 2px;
}
.exit-popup {
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 5;
  cursor: pointer;
  border: none;
  background-color: inherit;
}
.exit-popup i {
  font-size: 2.9rem;
  color: #cacaca;
}
</style>
