import { render, staticRenderFns } from "./VueGoogleAutoComplete.vue?vue&type=template&id=810c22ee&scoped=true&"
import script from "./VueGoogleAutoComplete.vue?vue&type=script&lang=js&"
export * from "./VueGoogleAutoComplete.vue?vue&type=script&lang=js&"
import style0 from "./VueGoogleAutoComplete.vue?vue&type=style&index=0&id=810c22ee&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "810c22ee",
  null
  
)

export default component.exports