<template>
	<section class="owner section section--padding">
		<div class="container">
			<div class="owner__content-container">
				<h2 class="section__title">{{ restOwners.title }}</h2>
				<p class="owner__copy">
					{{ restOwners.copy }}
				</p>

				<div class="owner__quote">
					{{ restOwners.quote }}
				</div>
				<div class="owner__info">
					{{ restOwners.quoteBy }}
				</div>
				<a class="button" href="/partners">Learn More</a>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		restOwners: Object
	}
};
</script>