<template>
	<section class="section section--yellow">
		<div class="container">
			<div class="app-section">
				<div class="app-section__side app-section__side--content">
					<div class="app-section__content">
						<h2 class="section__title section__title--black">
							{{ appSectionTitle }}
						</h2>
						<div class="section__subtitle">
							{{ appSectionCopy }}
						</div>
						<div class="app-section__buttons">
							<a
								:href="`https://itunes.apple.com/us/app/${homePageLinks.iOSCustomerApp}?mt=8`"
								target="_blank"
								class="app-section__button"
							>
								<img
									width="135"
									height="40.1"
									:src="$CDN + '/images/btn-apple.png'"
									class="app-apple"
									alt="apple app store badge"
								/>
							</a>
							<a
								:href="`https://play.google.com/store/apps/details?id=${homePageLinks.androidCustomerApp}`"
								target="_blank"
								class="app-section__button"
							>
								<img
									width="135"
									height="39.81"
									:src="$CDN + '/images/btn-google.png'"
									alt="android play store badge"
								/>
							</a>
						</div>
					</div>
				</div>
				<div class="app-section__side app-section__side--image">
					<img
						width="370"
						height="289.5"
						class="app-section__image"
						:src="appImageURL"
						alt="Someone using the Takeout Central app to order
						food"
					/>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		homePageLinks: {
			type: Object
		},
		appSectionTitle: String,
		appSectionCopy: String,
		appImageURL: String
	}
};
</script>